<template>
  <v-card class="card-product bg-gray-100">
    <v-img :src="product.src" lazy-src="@/assets/loading.gif" class="img"></v-img>
    <v-icon class="icon-edit" @click="$emit('edit', product)">mdi-pencil-outline</v-icon>
    <v-card-text class="card-product-text text-gray-700 bg-gray-100">
      <h2 class="price">{{ formattedSalePrice }}</h2>
      <p class="sell">Lucro: {{ formattedProfit }}</p>
      <p class="name">{{ product.name }}</p>
      <v-chip v-for="category in product.categories.filter(c => c != null)" :key="category.id" class="ma-2"
        color="#00000099" outlined small>
        {{ category.name }}
      </v-chip>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'ProductCard',
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  computed: {
    formattedSalePrice() {
      return parseFloat(this.product.sale_price).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    },
    formattedProfit() {
      const profit = parseFloat(this.product.sale_price) - parseFloat(this.product.cost_price);
      return profit.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }
  }
};
</script>

<style scoped>
.card-product {
  box-shadow: none !important;
  border: 1px solid rgba(229, 231, 235, 1);
}

.price {
  font-weight: 400;
  font-size: 1.1rem;
  padding-left: 10px;
}

.sell {
  font-size: 0.8rem;
  margin: 0;
  font-weight: 400;
  padding-left: 10px;
}

.name {
  margin: 1rem 0 0 10px;
  font-size: 1.3rem;
  font-weight: 400;
}

.img {
  position: relative;
}

.icon-edit {
  position: absolute;
  font-size: 1.2rem;
  top: 5px;
  right: 5px;
  color: #00AF7D;
  cursor: pointer;
}

.card-product-text {
  padding: 0.4rem;
}
</style>
