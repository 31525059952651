<template>
  <v-container fluid class="container-page">
    <v-row>
      <v-col cols="12" md="12">
        <v-row>
          <v-col cols="12" md="12">
            <h2>Pedidos</h2>
          </v-col>
          <v-col>
            <v-data-table :headers="headers" :items="orders" :page.sync="page" :items-per-page="itemsPerPage"
              hide-default-footer class="elevation-1" @page-count="pageCount = $event">
              <template v-slot:[`item.items`]="{ item }">
                <div class="column-pos">
                  <span class="column-title">BEE{{ (item.id + "").padStart(6, "0") }}</span><br>
                  <span class="column-subtitle">{{ item.products.length }} produto(s)</span>
                </div>
              </template>

              <template v-slot:[`item.date`]="{ item }">
                <div class="column-pos">
                  <span class="column-title">{{ new Date(item.createdAt).toLocaleDateString() }}</span><br>
                  <span class="column-subtitle">{{ new Date(item.createdAt).toLocaleTimeString() }}</span>
                </div>
              </template>

              <template v-slot:[`item.comission`]="{ item }">
                <div class="column-pos">
                  <span class="column-title" v-if="item.releaseDate">{{ item.releaseDate == null ? 'Calculando' : new Date(item.releaseDate).toLocaleDateString() }}</span>
                  <span class="column-title" v-else>-</span>
                  <br>
                  <span class="column-subtitle"></span>
                </div>
              </template>

              <template v-slot:[`item.status`]="{ item }">
                <div class="column-pos">
                  <span class="column-title"
                    v-if="item.chargeStatus == 'settled' || item.chargeStatus == 'paid' || item.chargeStatus == 'approved'">
                    <v-chip class="ma-2" color="green" outlined>
                      Pago
                    </v-chip>
                  </span>
                  <span class="column-title" v-if="item.chargeStatus == 'unpaid'">
                    <v-chip class="ma-2" color="red" outlined>
                      Vencido
                    </v-chip>
                  </span>

                  <span class="column-title" v-if="item.chargeStatus == 'waiting'">
                    <v-chip class="ma-2" color="primary" outlined>
                      Aguardando Pagamento
                    </v-chip>
                  </span>
                  <br>
                  <div v-if="item.chargeProvider == null">
                    Buscando...
                  </div>
                  <div v-if="item.chargeProvider == 'efi'">
                    PIX
                  </div>
                  <div v-if="item.chargeProvider == 'mercadopago'">
                    <span class="column-subtitle">Cartão de Crédito</span>
                  </div>

                </div>
              </template>

              <template v-slot:[`item.price`]="{ item }">
                <div class="column-pos">
                  <span class="column-title">{{ item.total.toLocaleString('pt-BR', {
                    style: 'currency', currency: 'BRL'
                  })
                    }}</span><br>
                  <span class="column-subtitle">{{ (item.subtotal - item.costPrice).toLocaleString('pt-BR', {
                    style:
                      'currency', currency: 'BRL'
                  }) }}</span>
                </div>
              </template>

              <template v-slot:[`item.costumer`]="{ item }">
                <div class="column-pos">
                  <span class="column-title">{{ item.name }}</span>
                </div>
              </template>

              <template v-slot:[`item.shipping`]="{ item }">
                <div class="column-pos">
                  <span class="column-title" v-if="item.shippingStatus == 'unshipped'">
                    <v-chip class="ma-2" outlined>
                      Cancelado
                    </v-chip>
                  </span>
                  <span v-else>
                    <span class="column-title" v-if="item.shippingStatus == 'shipped'">
                      <v-chip class="ma-2" outlined color="primary">
                        Enviado
                      </v-chip><br>
                      <small>{{ item.shippingTrackingNumber }}</small>
                    </span>
                    <span class="column-title" v-else>
                      <v-chip class="ma-2" outlined color="orange">
                        Preparando
                      </v-chip>
                    </span>
                  </span>
                </div>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-btn color="#00AF7D" outlined @click="openDialog(item)">Detalhes</v-btn>
              </template>
            </v-data-table>
            <div class="text-center pt-2">
              <v-pagination v-model="page" :length="pageCount"></v-pagination>
              <!-- <v-text-field
                    :value="itemsPerPage"
                    label="Produtos por página"
                    type="number"
                    min="-1"
                    max="15"
                    @input="itemsPerPage = parseInt($event, 10)"
                  ></v-text-field> -->
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <Loading v-if="loadingPage" />

    <!-- Dialog for Order Details -->
    <v-dialog v-model="dialog" max-width="600" class="dialog-spacing">
      <v-card>
        <v-card-title class="headline">Detalhes do Pedido</v-card-title>
        <v-card-text>
          <div v-if="selectedOrder">
            <p><strong>ID:</strong> BEE{{ (selectedOrder.id + "").padStart(6, "0") }}</p>
            <p><strong>Data:</strong> {{ new Date(selectedOrder.createdAt).toLocaleDateString() }}</p>
            <p><strong>Cliente:</strong> {{ selectedOrder.name }}</p>
            <p><strong>Contato:</strong> {{ selectedOrder.email }} | {{ selectedOrder.phone }}</p>
            <p><strong>Produtos:</strong></p>
            <ul>
              <li v-for="product in selectedOrder.products" :key="product.id">{{ product.quantity }}x - {{
                product.product.name }}
              </li>
            </ul> <br>
            <p><strong>Subtotal:</strong> {{ selectedOrder.subtotal.toLocaleString('pt-BR', {
              style: 'currency',
              currency:
                'BRL'
            }) }}
            </p>
            <p><strong>Frete:</strong> {{ selectedOrder.shipping.toLocaleString('pt-BR', {
              style: 'currency',
              currency:
                'BRL'
            }) }}</p>
            <p><strong>Total:</strong> {{ selectedOrder.total.toLocaleString('pt-BR', {
              style: 'currency', currency:
                'BRL'
            }) }}</p>
          </div>
          <div v-else>
            <p>Carregando...</p>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#00AF7D" text @click="closeDialog">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Loading from '@/components/Loading.vue'
import axios from 'axios'
import config from '@/store/config'
const api = axios.create({
  baseURL: config.baseURL
})

export default {
  name: 'OrdersPage',
  metaInfo() {
    return {
      title: `Pedidos - ${config.name} - Beeasy - ${this.userData.fullName}`
    }
  },
  components: {
    Loading
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 5,
      headers: [
        {
          text: 'Venda/Itens',
          align: 'center',
          sortable: false,
          value: 'items',
        },
        { text: 'Data/Hora', value: 'date', align: 'center', },
        { text: 'Data Repasse', value: 'comission', align: 'center', },
        { text: 'Status/Método', value: 'status', align: 'center', },
        { text: 'Faturado/Lucro', value: 'price', align: 'center', },
        { text: 'Cliente', value: 'costumer', align: 'center', },
        { text: 'Status Entrega', value: 'shipping', align: 'center', },
        { text: 'Ações', value: 'actions', align: 'center', sortable: false },
      ],
      orders: [],
      userData: {},
      storeData: {},
      loadingPage: false,
      dialog: false,
      selectedOrder: null
    }
  },
  async mounted() {
    await this.init()
    this.$store.commit('insertLog',{ action: 'Navegacao',  description: `[${this.userData.fullName}] acessou a página de pedidos`})
  },
  methods: {
    async init() {
      this.loadingPage = true
      this.userData = this.$store.getters.getUserData
      this.storeData = this.$store.getters.getStoreData
      await this.getOrders()
      this.loadingPage = false
    },
    async getOrders() {
      try {
        const { data } = await api.get(`/orders`, await this.$store.getters.getConfig)
        this.orders = data
      } catch (error) {
        console.error(error)
      }
    },
    openDialog(item) {
      this.selectedOrder = item
      this.dialog = true
      this.$store.commit('insertLog',{ action: 'Consulta',  description: `[${this.userData.fullName}] acessou detalhes do pedido {${item.id}}`})
    },
    closeDialog() {
      this.dialog = false
      this.selectedOrder = null
    }
  }
}
</script>

<style scoped>
* {
  font-family: "Outfit", sans-serif;
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.input-default {
  width: 100%;
  padding: .5rem;
  color: #404049;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 5px;
  border: 1px solid rgba(64, 64, 73, .3);
}

.button-default {
  width: 100%;
  padding: .5rem;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 5px;
  background-color: #00AF7D;
  border: 1px solid #00AF7D;
  cursor: pointer;
}

.column-pos {
  text-align: center;
  color: rgb(55 65 81);
  padding: .5rem;
}

.column-title {
  font-size: 1rem;
  font-weight: 400;
}

.column-subtitle {
  font-size: .8rem;
  font-weight: 400;
}

/* Estilo adicional para garantir espaçamento em telas móveis */
.dialog-spacing {
  margin: 16px !important;
}

@media (min-width: 600px) {
  .dialog-spacing {
    margin: 24px !important;
  }
}
</style>
